import { URL_PARAMS } from 'src/constants';
import { getUrlParam } from 'src/utils/urlUtils';
import { cloneDeep, isEmpty, isObject } from 'lodash';

export function mapFiltersByColumnToFilterById(preselected, nativeFilters) {
  if (!preselected || isEmpty(preselected) || isEmpty(nativeFilters?.filters)) {
    return preselected;
  }
  const updatedFilters = Object.entries(preselected).reduce(
    (acc, [key, item]) => {
      if (key.startsWith('NATIVE_FILTER-') || !isObject(item)) {
        // Leave as is
        return {
          ...acc,
          [key]: item,
        };
      }

      const nativeFilterPairs = Array.from(
        Object.entries(nativeFilters.filters),
      );

      // Find all matching filters
      const matchingIds = nativeFilterPairs
        .filter(
          // eslint-disable-next-line no-unused-vars
          ([id, definition]) =>
            definition?.targets?.[0].column?.name === key ||
            definition?.targets?.[0].column === key,
        )
        .map(([id]) => id);

      if (matchingIds.length === 0) {
        // No matching filter, leave as is
        return {
          ...acc,
          [key]: item,
        };
      }

      let updatedAcc = { ...acc };

      // Apply the updates for each matching filter
      matchingIds.forEach(newId => {
        const updated = cloneDeep(item);
        updated.id = newId;
        if (item.filterState) {
          updated.filterState.label = updated.filterState.value?.[0] ?? null;
          // eslint-disable-next-line no-underscore-dangle
          updated.__cache = updated.__cache ?? cloneDeep(updated.filterState);
          updated.extraFormData = updated.extraFormData ?? {
            filters: [
              {
                col: key,
                op: 'IN',
                val: item.filterState.value,
              },
            ],
          };
          updated.ownState = {};
        }

        updatedAcc = {
          ...updatedAcc,
          [newId]: updated,
        };
      });

      return updatedAcc;
    },
    {},
  );

  return updatedFilters;
}

/** Extracts native filter values from the URL and converts the filter keys
 * (as needed) from column name to ID so they can work on multiple dashboards.
 * This allows an embedding app to apply filters without knowledge of the filter
 * IDs.
 */
export function getPreselectedNativeFilters(nativeFilters) {
  const preselected = getUrlParam(URL_PARAMS.nativeFilters);
  return mapFiltersByColumnToFilterById(preselected, nativeFilters);
}
